import { types } from 'mobx-state-tree';
import { markDownloadLesson } from 'api';
import compareDesc from 'date-fns/compareDesc';
import compareAsc from 'date-fns/compareAsc';
import { groupBy } from 'utils';
import { segmentEvent } from 'utils/segmentTrackers';

import Module from 'store/models/Module';
import Instructor from 'store/models/Instructor';
import Resource from 'store/models/Resource';
import Image from 'store/models/Image';
import FreeTrialInfo from 'store/models/FreeTrialInfo';
import Term from 'store/models/Term';
import QASession from 'store/models/QASession';
import FavoriteLesson from 'store/models/FavoriteLesson';
import Notification from 'store/models/Notification';
import VideoAssignment from './VideoAssignment';

const Class = types
  .model({
    id: types.identifierNumber,
    title: types.string,
    type: types.string,
    totalProgress: types.maybeNull(types.number),
    price: types.maybeNull(types.number),
    curriculumType: types.maybeNull(types.string),
    // curriculum: types.string,
    image: types.maybeNull(Image),
    categories: types.array(types.string),
    instructors: types.maybeNull(types.array(Instructor)),
    instructorDisplay: types.maybeNull(types.string),
    teachingAssistants: types.maybeNull(types.array(Instructor)),
    resources: types.maybeNull(types.array(Resource)),
    // modules: types.array(Module),
    modules: types.maybeNull(types.array(Module)),
    freeTrialInfo: types.maybeNull(FreeTrialInfo),
    userType: types.maybeNull(
      types.enumeration(['NA', 'ta', 'administrator', 'student', 'instructor', 'previewer'])
    ),
    urlKey: types.maybeNull(types.string),
    term: types.maybeNull(Term),
    terms: types.maybeNull(types.array(Term)),
    description: types.maybeNull(types.string),
    officeHours: types.maybeNull(types.array(QASession)),
    favoriteLessons: types.optional(types.array(FavoriteLesson), []),
    notifications: types.maybeNull(
      types.model({
        read: types.maybeNull(types.array(Notification)),
        unread: types.maybeNull(types.array(Notification)),
      })
    ),
    classStarted: types.maybeNull(types.boolean),
    videoAssignments: types.optional(types.array(VideoAssignment), []),
    disableFirstModule: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get category() {
      return self.categories?.length
        ? self.categories.filter((cat) => cat.toLowerCase() !== 'featured')[0]
        : '';
    },

    get allLessons() {
      let lessons = [];
      self.modules.forEach((mod) => {
        lessons = lessons.concat(mod.allLessons);
      });

      return lessons;
    },

    /**
     * (1) Get the first uncompleted, but unlocked video lesson index.
     * (2) If all video lessons are completed, return the last viewed video lesson.
     * (3) If no new video lessons are unlocked (because other types of lessons need to be completed
     * first), then find the last completed video lesson.
     * (4) If no completed video lesson found in (2), return the current lesson
     */
    get nextVideoLessonIdx() {
      const { allLessons, isLessonViewableMap } = self;

      // find the first uncompleted, but unlocked video lesson index
      const idx = allLessons.findIndex(
        (l) => isLessonViewableMap[l.id] && l.isVideoLesson && !l.completed && l.videoId
      );
      // if idx is a valid index number, return the index.
      if (idx >= 0 && idx < allLessons.length) return idx;

      // all lessons completed?
      if (allLessons[allLessons.length - 1].completed) {
        return allLessons.findIndex((l) => l.currentLesson);
      }

      // other lesson types pending completion?
      const reverseIdx = [...allLessons]
        .reverse()
        .findIndex((l) => isLessonViewableMap[l.id] && l.isVideoLesson && l.completed && l.videoId);

      return reverseIdx !== -1
        ? allLessons.length - 1 - reverseIdx
        : allLessons.findIndex((l) => l.currentLesson);
    },

    get nextVideoLesson() {
      // added to prevent errors in dev that happen due to the invalid class data (classId: 92)
      const idx = self.nextVideoLessonIdx === -1 ? 0 : self.nextVideoLessonIdx;
      return self.allLessons[idx];
    },
    /**
     * (1) if class is not started, set the first lesson as a current lesson
     * (2) Find current, uncompleted, & unlocked lesson
     * (3) If there is no current, uncompleted and unlocked lesson, find the next lesson uncompleted (starting from the current and completed lesson)
     * (4) If no next uncompleted lesson, find the first uncompleted lesson
     * (5) If there are no uncompleted lessons, return the current lesson
     */
    get nextLessonIdx() {
      const { allLessons, isLessonViewableMap, classStarted } = self;
      // if class is not started, set the first lesson as a current lesson
      if (!classStarted) {
        self.changeCurrentLesson(allLessons[0].id);
        return 0;
      }
      // find current, uncompleted, & unlocked lesson
      const idx = allLessons.findIndex(
        (l) => isLessonViewableMap[l.id] && !l.completed && l.currentLesson && l.isValidLesson
      );
      if (idx !== -1) {
        return idx;
      }

      // check for current & completed
      const currentCompletedSession = allLessons.findIndex((l) => l.completed && l.currentLesson);
      const lastIndex = allLessons.length - 1;
      // if there is currentCompleted and if it's not the last, return the next uncompleted
      if (currentCompletedSession !== -1 && currentCompletedSession !== lastIndex) {
        const nextUncompleted = allLessons
          .slice(currentCompletedSession + 1)
          .findIndex((l) => isLessonViewableMap[l.id] && !l.completed && l.isValidLesson);
        if (nextUncompleted !== -1) {
          const nextUncompletedIdx = nextUncompleted + currentCompletedSession + 1;
          self.changeCurrentLesson(allLessons[nextUncompletedIdx].id);
          return nextUncompletedIdx;
        }
      }

      // find the first that's uncompleted
      const firstUncompleted = allLessons.findIndex(
        (l) => !l.completed && isLessonViewableMap[l.id] && l.isValidLesson
      );
      if (firstUncompleted !== -1) {
        self.changeCurrentLesson(allLessons[firstUncompleted].id);
        return firstUncompleted;
      }

      // if no results return the currentLesson
      const current = allLessons.findIndex((l) => l.currentLesson);
      self.changeCurrentLesson(allLessons[current].id);
      return current;
    },

    get nextLiveSession() {
      const { allLessons } = self;

      const upcomingliveSessions = allLessons
        .filter((l) => l.isUpcomingLiveSession)
        .sort((a, b) =>
          compareAsc(
            new Date(a.firstLiveSessionInstance?.startDate),
            new Date(b.firstLiveSessionInstance?.startDate)
          )
        );

      return upcomingliveSessions[0];
    },

    get nextLesson() {
      return self.allLessons[self.nextLessonIdx];
    },

    get imageUrl() {
      return `https://res.mindbodygreen.com/${self.image.path}`;
    },

    // find the first module index that has some of its lesson incomplete
    get nextModuleIdx() {
      let nextModuleIdx = self.modules.findIndex((m) => !m.completed);
      if (nextModuleIdx < 0) nextModuleIdx = self.modules.length - 1; // if all modules are completed
      return nextModuleIdx;
    },

    get nextModule() {
      return self.modules[self.nextModuleIdx];
    },

    get currentModuleIdx() {
      const { modules, nextLesson } = self;
      // find module where the next lesson is
      let idx = modules.findIndex((m) => m.id === nextLesson.moduleId);
      // if module couldn't be found, return the first incomplete
      if (idx < 0) idx = self.nextModuleIdx;

      return idx;
    },

    get nestedModuleIdx() {
      const module = self.modules[self.currentModuleIdx];
      if (module.modules?.length) {
        const activeNested = module.modules.findIndex((m) =>
          m.containsLessonId(self.nextLesson.id)
        );
        if (activeNested !== -1) return activeNested;
      }

      return -1;
    },

    get currentModule() {
      const module = self.modules[self.currentModuleIdx];
      const nestedModule =
        self.nestedModuleIdx !== -1 ? module.modules[self.nestedModuleIdx] : null;
      return nestedModule || module;
    },

    // returns a mapping of lesson id to a boolean indicating if the lesson can be viewed
    get isLessonViewableMap() {
      const map = {};
      const freeTrialActive =
        !!self.freeTrialInfo &&
        !!self.freeTrialInfo.activated &&
        self.freeTrialInfo.secondsRemaining > 0;

      const isAdmin =
        self.userType === 'instructor' ||
        self.userType === 'ta' ||
        self.userType === 'administrator';

      const isStudent = self.userType === 'student';

      let available = true;
      self.allLessons.forEach((lesson) => {
        const { id, completed } = lesson;
        map[id] = available;
        available = available && (isStudent || isAdmin || completed || freeTrialActive);
      });

      return map;
    },

    /* eslint-disable no-plusplus */

    // return the first assignment lesson that is not completed, but only if all the previous lessons were completed
    get nextAssignment() {
      const { allLessons } = self;
      let cursor = 0;
      while (cursor < allLessons.length) {
        const next = allLessons[cursor++];
        if (next.type !== 'Assignment' && !next.completed) break;
        if (next.type === 'Assignment' && !next.completed) return next;
      }

      return null;
    },

    get nextQuizIdx() {
      const { allLessons } = self;
      let cursor = 0;
      while (cursor < allLessons.length) {
        const next = allLessons[cursor++];
        if (next.type !== 'Assessment' && !next.completed) break;
        if (next.type === 'Assessment' && !next.completed) return cursor;
      }

      return -1;
    },

    get allMaterials() {
      const classLevelMaterials = self.resources || [];
      const moduleMaterials = self.modules.reduce((agg, next) => agg.concat(next.materials), []);
      return classLevelMaterials.concat(moduleMaterials);
    },

    get allModuleMaterials() {
      return self.modules.reduce((agg, next) => agg.concat(next.materials), []);
    },

    get hasAssignments() {
      return self.allLessons.some((lsn) => lsn.type === 'Assignment');
    },

    get upcomingOfficeHours() {
      return self.officeHours ? self.officeHours.filter((oh) => oh.isUpcoming) : [];
    },

    get pastOfficeHours() {
      return self.officeHours
        ? groupBy(
            self.officeHours.filter((oh) => !oh.isUpcoming),
            (oh) =>
              oh.instructorNames && oh.instructorNames.length ? oh.instructorNames[0] : 'Unknown'
          )
        : [];
    },
  }))
  .actions((self) => ({
    markLessonCompleted(lessonIndex) {
      self.allLessons[lessonIndex].markCompleted();
    },

    incrementFreeTrialSeconds() {
      if (!self.freeTrialInfo || !self.freeTrialInfo.activated) return;
      self.freeTrialInfo.totalSecondsConsumed++;
    },

    containsLesson(lessonId) {
      return self.modules.some((m) => m.containsLessonId(lessonId));
    },

    activateFreeTrial() {
      self.freeTrialInfo.activated = true;
      self.freeTrialInfo.totalSecondsConsumed = 0;
    },

    async markDownloadComplete(lessonId) {
      // lesson unlocked?
      if (!self.isLessonViewableMap[lessonId]) return;

      // Segment Analytics
      const lesson = self.allLessons.find((l) => l.id === lessonId);
      segmentEvent('Class Materials Downloaded', {
        classId: self.id,
        lessonId,
        title: lesson.title,
      });

      // In case the first lesson of a class is a Download lesson and a user has not started the class yet,
      // need to mark this boolean "classStarted" to true in order to get the next lesson index,
      // which is a return value of a function "nextLessonIdx" using this boolean value.
      if (!self.classStarted) self.classStarted = true;

      self.markLessonCompleted(self.allLessons.findIndex((l) => l.id === lessonId));

      try {
        const response = await markDownloadLesson(
          self.id,
          self.term ? self.term.id : undefined,
          lessonId
        );

        self.updateProgress(response.totalProgress);
        if (response.newUnreadNotification) {
          self.addUnreadNotification(response.newUnreadNotification);
        }
      } catch (error) {
        console.error(error);
      }
    },

    changeCurrentLesson(lessonId) {
      self.modules.forEach((mod) => mod.changeCurrentLesson(lessonId));
    },

    assignmentsByModule(moduleId) {
      const mod = self.modules.find((m) => m.id === moduleId);
      return mod.allLessons
        .filter((a) => a.moduleId === moduleId && a.type === 'Assignment')
        .sort((a, b) => compareDesc(new Date(a.createDate), new Date(b.createDate)));
    },

    pushFavoriteLesson(lesson) {
      self.favoriteLessons.push(lesson);
    },

    removeFavoriteLesson(lesson) {
      self.favoriteLessons = self.favoriteLessons.filter((l) => l.id !== lesson.id);
    },

    addUnreadNotification(notification) {
      if (!self.notifications) {
        self.notifications = {
          read: [],
          unread: [],
        };
      }

      if (!self.notifications.unread) self.notifications.unread = [];

      // check if the same notification is in the store
      // backend sometimes returns the same unread notification on every progress tracking update
      const isInStore = self.notifications.unread.find((n) => n.id === notification.id);

      if (!isInStore) {
        self.notifications.unread.unshift(notification);
      }
    },

    clearUnreadNotifications() {
      if (!self.notifications) return;

      if (!self.notifications.read) {
        self.notifications.read = [];
      }

      self.notifications.read.concat([...self.notifications.unread]);
      self.notifications.unread = [];
    },

    updateProgress(progress) {
      self.totalProgress = progress;
    },

    setClassStarted() {
      self.classStarted = true;
    },
  }));

export default Class;
