import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import styled, { ThemeProvider } from 'styled-components';
import cookies from 'js-cookie';

import 'flatpickr/dist/themes/light.css';

import appTheme from 'appTheme';
import { Provider, rootStore } from 'store';
import useBeforeFirstRender from 'utils/useBeforeFirstRender';
import { segmentIdentify } from 'utils/segmentTrackers';
import Layout from 'components/core/layout';

import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchUser } from 'api';

const store = { rootStore };

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  integrations: [Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

Amplify.configure(awsconfig);

// eslint-disable-next-line react/prop-types
const ClassesApp = ({ Component, pageProps }) => {
  const [impersonateUser, setImpersonateUser] = useState();
  /**
   * Global auth wrapper. Redirects to login page if no session cookie is found.
   */
  useBeforeFirstRender(() => {
    if (!process.browser) return;
    const search = window.location.search.split('=');
    const impersonateUserId = search[0] === '?impersonate' ? search[1] : null;

    window.gtmDataLayer = window.gtmDataLayer || window.dataLayer || [];

    const sessionCookie = cookies.get('cl_ac');
    const hashIdCookie = cookies.get('h_id');
    const previewTokenCookie = cookies.get('c_ps');
    // always require a session cookie or a preview cookie
    if (
      process.env.NODE_ENV !== 'development' &&
      !location.pathname.includes('user/classes/calls/join') &&
      !location.pathname.includes('user/classes/calls/watch') &&
      !location.pathname.includes('instructors/submissions/') &&
      !sessionCookie &&
      (!hashIdCookie || !previewTokenCookie)
    ) {
      window.location.href = 'https://www.mindbodygreen.com/account/logout/learn';
      return;
    }

    if (impersonateUserId && rootStore.dashboardStore.isAdmin) {
      sessionStorage.setItem('impersonateUserId', impersonateUserId);
    } else {
      sessionStorage.removeItem('impersonateUserId');
      if (impersonateUserId) {
        // eslint-disable-next-line prefer-destructuring
        window.location.href = window.location.href.split('?')[0];
      }
    }

    (async function initializeSentryUser() {
      try {
        const user = await fetchUser();
        if (user) {
          Sentry.setUser({
            id: user.userId,
            email: user.emailAddress,
          });
        }
      } catch (err) {
        console.error(err);
      }
    })();

    Amplify.configure({
      API: {
        graphql_headers: async () => ({
          'user-session-id': sessionCookie,
          'user-hash-id': hashIdCookie,
          'preview-token': previewTokenCookie,
          'impersonate-user-id': impersonateUserId,
        }),
      },
    });
  });

  // remove this
  useEffect(() => {
    if (sessionStorage.getItem('impersonateUserId') && rootStore.dashboardStore.isAdmin) {
      setImpersonateUser(sessionStorage.getItem('impersonateUserId'));
    } else if (process.env.USER_ID) segmentIdentify(process.env.USER_ID);
  }, [setImpersonateUser]);

  return (
    <Provider value={store}>
      <ThemeProvider theme={appTheme}>
        <Layout
          makeSpaceForWarning="4rem"
          warning={
            impersonateUser && (
              <ImpersonateWarning>
                You are impersonating user with id: {impersonateUser}, progress tracking and
                analytics are disabled.
              </ImpersonateWarning>
            )
          }
        >
          <ToastContainer
            theme="dark"
            position="bottom-left"
            autoClose={2000}
            closeOnClick
            hideProgressBar={true}
          />
          <Component {...pageProps} />
        </Layout>
      </ThemeProvider>
    </Provider>
  );
};

export const ImpersonateWarning = styled.div`
  width: 100%;
  background: #ffbf00;
  position: fixed;
  z-index: 13;
  max-width: 144rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem 0;
  top: 5.5rem;
  color: white;

  @media (min-width: 1440px) {
    left: calc(50% - 1440px / 2);
    top: 5.5rem;
  }
`;

// ClassesApp.getInitialProps = async ({ Component, ctx }) => {
//   // parse query params and set h_id and c_ps cookies
//   if (ctx.req && ctx.query.hId && ctx.query.cPs) {
//     ctx.res.setHeader('Set-Cookie', [`h_id=${ctx.query.hId}`, `c_ps=${ctx.query.cPs}`]);
//   }

//   ctx.res.setHeader('Set-Cookie', [`h_id_test="valuetest"`, `c_ps_test="valuetest"`]);

//   let pageProps = {};
//   if (Component.getInitialProps) pageProps = await Component.getInitialProps(ctx);
//   return { pageProps };
// };

// ClassesApp.getInitialProps = async ({ Component, ctx }) => {
//   // const { cl_ac: sessionCookie } = cookies(ctx);
//
//   const innerProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
//   const pageProps = { ...innerProps };
//
//   // not using SSR prefetching at the moment
//   // if (!process.browser && Component.getServerData) {
//   // const data = await Component.getServerData(ctx);
//   // const store = initializeStore(data);
//   // pageProps.initialState = getSnapshot(store);
//   // }
//
//   return { pageProps };
// };

export default ClassesApp;
