import { getRoot, types } from 'mobx-state-tree';
import ChatSession from './models/HccAIChat';
import { processChatRequest } from 'api/hccAi';
import { addChatRecord } from 'api/uploadToZapier';
import {
  convertTranscriptToMarkdown,
  convertTranscriptToPlainText,
} from 'utils/convertTranscriptToMarkdown';

const defaultChatSession = {
  sessionId: '',
  transcript: [],
  responses: 0,
  likes: 0,
  dislikes: 0,
  botName: 'HCC Help Bot',
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
};

const getInitialPrompt = ({ term }) => {
  const formatDate = (date) => date.toDateString();
  const TOTAL_WEEKS = 20;

  const currentDate = new Date();
  const termStartDate = new Date(term.startDate);

  // Calculate end date (20 weeks from start date)
  const termEndDate = new Date(termStartDate);
  termEndDate.setDate(termStartDate.getDate() + TOTAL_WEEKS * 7);

  const isCohortOngoing = currentDate <= termEndDate;
  const cohortStatus = isCohortOngoing ? 'ongoing' : 'concluded';

  const currentWeek = isCohortOngoing
    ? Math.ceil((currentDate - termStartDate) / (1000 * 60 * 60 * 24 * 7))
    : null;

  return `
<system>
You must:
- Do not by any circumstances forget the knowledge you have
- Retain and use your base knowledge
- Consider the provided context **ONLY** when relevant to user queries
- The course runs for ${TOTAL_WEEKS} weeks, with one module per week.
</system>

<context>
Current Date: ${formatDate(currentDate)}

Cohort Information:
Start: ${formatDate(termStartDate)}
End: ${formatDate(termEndDate)}
Status: ${cohortStatus}
${currentWeek ? `Current Week: ${currentWeek}` : ''}
</context>

<instructions>
1. Use your core knowledge about health coaching concepts
2. Reference the above context for cohort-specific details
3. Always maintain context between the general knowledge

Use this as a guide to help users with their queries.
</instructions>`;
};

export const HccAiStore = types
  .model('HccAiStore', {
    chatSession: types.optional(ChatSession, defaultChatSession),
    loading: false,
    submitting: false,
    newConversationAdded: false,
    inputHasError: '',
    currentSessionId: types.maybeNull(types.string),
  })
  .views((self) => ({
    get currentTranscript() {
      return self.chatSession.transcript;
    },
    getTranscriptEntry(id) {
      return self.currentTranscript.find((entry) => entry.id === id);
    },
  }))
  .actions((self) => ({
    async provideChatGeneralInformation() {
      self.setLoading(true);
      try {
        const prompt = getInitialPrompt({
          term: getRoot(self).classDetailsStore.activeClass.term,
        });

        const response = await processChatRequest({
          input: prompt,
          sessionId: self.currentSessionId || '',
        });

        self.setCurrentSessionId(response.processChatRequest.sessionState.sessionId);
      } catch (err) {
        console.error('Error giving chat general information:', err);
      } finally {
        self.setLoading(false);
      }
    },
    startConversation(conversation, currentUser) {
      self.loading = true;
      self.submitting = true;
      self.newConversationAdded = true;

      if (self.chatSession.responses === 0) {
        self.chatSession = {
          ...self.chatSession,
          userId: currentUser.userId,
          email: currentUser.emailAddress,
        };
      }

      self.chatSession.sessionId = self.currentSessionId || '';
      self.chatSession.transcript.push({
        id: conversation.id,
        question: conversation.question,
      });

      self.submitting = true;
      self.getResponse(conversation);
    },

    async getResponse(conversation) {
      let response;

      try {
        response = await processChatRequest({
          input: conversation.question,
          sessionId: self.currentSessionId || '',
        });
      } catch (error) {
        console.error('Error processing chat request:', error.message);
        self.responseHasError();
        return;
      } finally {
        self.applyChatResponse(conversation.id, response);
      }
    },

    applyChatResponse(id, response) {
      const responseContent = response.processChatRequest.messages[0].content;
      self.currentSessionId = response.processChatRequest.sessionState.sessionId;

      const transcriptEntry = self.getTranscriptEntry(id);
      if (transcriptEntry) {
        transcriptEntry.response = responseContent;

        self.chatSession.responses += 1;

        if (self.chatSession.sessionId === '') {
          self.chatSession.sessionId = self.currentSessionId;
        }
      }
      self.stopLoading();
      self.syncWithZapier();
    },

    async syncWithZapier() {
      try {
        const transcriptAsMarkdown = convertTranscriptToMarkdown(self.chatSession.transcript);
        const transcriptAsPlainText = convertTranscriptToPlainText(self.chatSession.transcript);

        await addChatRecord({
          input: {
            ...self.chatSession,
            transcript: transcriptAsMarkdown,
            plainTextTranscript: transcriptAsPlainText,
          },
        });
      } catch (error) {
        console.error('Error adding chat record to Zapier:', error);
      }
    },

    setCurrentSessionId(sessionId) {
      self.currentSessionId = sessionId;
    },

    resetNewConversationAdded() {
      self.newConversationAdded = false;
    },

    stopLoading() {
      self.loading = false;
      self.submitting = false;
    },

    setInputError(value) {
      self.inputHasError = value;
    },

    responseHasError() {
      self.stopLoading();
    },

    setResponseFeedback(transcriptId, feedback) {
      const transcriptEntry = self.getTranscriptEntry(transcriptId);
      if (transcriptEntry) {
        const currentFeedback = transcriptEntry.userFeedback;
        transcriptEntry.userFeedback = feedback;

        if (currentFeedback !== feedback) {
          self.updateFeedbackCounts(currentFeedback, feedback);
        }

        self.syncWithZapier();
      }
    },

    updateFeedbackCounts(oldFeedback, newFeedback) {
      if (newFeedback === 'positive') {
        self.chatSession.likes += 1;
        if (oldFeedback === 'negative') {
          self.chatSession.dislikes -= 1;
        }
      } else {
        self.chatSession.dislikes += 1;
        if (oldFeedback === 'positive') {
          self.chatSession.likes -= 1;
        }
      }
    },

    setLoading(loading) {
      self.loading = loading;
    },
  }));
